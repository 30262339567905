import React from 'react';
import Grid from '@mui/material/Grid';
import _ from 'lodash';
import ExternalSystemEdit from '../components/ExternalSystemEdit';
import { PopupForm } from '../components/Wrappers';
import Checkbox from '../components/Checkbox';

const getFullPubList = (pubs, selected) => [
	..._.difference(_.sortBy(selected), _.map(pubs, 'id')).map((id) => ({
		id, name: `[UNKNOWN id: ${id}]`, isUnknown: true,
	})),
	..._.sortBy(pubs, 'name'),
];

ExternalSystemEdit.registerCustomRenderer('AppnexusPubFilter', ({ system, sysProps: { form } }) => (
	<PopupForm
		selected={system.publisherFilter}
		title="Filtered publishers"
		size="sm"
		getModel={() => _.cloneDeep(system.publisherFilter)}
		fn={() => system.listPublishers()}
		content={({ opResult: pubs, model: publisherFilter, popup }) => (
			<>
				{getFullPubList(pubs, publisherFilter).map(({ name, id }) => (
					<Grid container key={id}>
						<Grid item xs="auto">
							<Checkbox
								formStyle={{ marginTop: 0, marginBottom: 0 }}
								label={name}
								name={name + id}
								value={publisherFilter.includes(id)}
								onChange={() => popup.update(() => {
									if (publisherFilter.includes(id)) {
										_.pull(publisherFilter, id);
									} else {
										publisherFilter.push(id);
									}
								})}
								margin="normal"
							/>

						</Grid>
					</Grid>
				))}
			</>
		)}
		onApplyChanges={({ model: publisherFilter }) => form.setVals({ publisherFilter })}
	/>
));
